D<template>
  <div class="addPlan">
    <div class="addPlan-wrap">
      <div class="addPlan-top">
        <div>{{ addtitle }}</div>
        <img @click="closefn" src="@/assets/images/pop01.png" alt="" />
      </div>
      <div class="addPlan-content">
        <div class="ff-c">
          <div class="fi-c">
            <div class="it">计划实际名称</div>
            <el-select v-model="ptaskid" class="m-2" placeholder="请选择计划进度表" size="large" @change="selectChange"  :disabled = "selectDisable">
              <el-option
                v-for="item in options"
                :key="item.ID"
                :label="item.P_Name"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="gt-c">
          <div class="gtc-t">
            <div class="gq-btn" @click="typeChange(1)">日视图</div>
            <div class="gq-btn" @click="typeChange(2)">月视图</div>
            <div class="gq-btn" @click="typeChange(3)">年视图</div>
            <div class="gq-btn" @click="typeChange(4)">默认视图</div>
            <div class="gq-btn" @click="showWeek(1)">显示周末</div>
            <div class="gq-btn" @click="showWeek(2)">隐藏周末</div>
            <div class="gq-btn" @click = "down">导出</div>
          </div>
          <div class="gtc-b" id="gantt_here"></div>
        </div>
        <div class="btns" v-if = "addtitle == '添加实际进度'">
          <div class="ac-btn" @click="closefn">取消</div>
          <div class="ac-btn" :class = "{disable:!ptaskid}" @click = "AddProductionProject">确认</div>
        </div>
        <div class="btns" v-if = "addtitle == '编辑实际进度'">
          <!-- <div class="ac-btn" @click="closefn">取消</div> -->
          <div class="ac-btn" @click="closefn">关闭</div>
        </div>
      </div>
      <div class="task-box" v-if = "taskboxshow">
        <div class="tbox-c">
          <div class="tbc-t">
            <div class="close-btn"  @click="cancel">&times;</div>
          </div>
          <div class="tbc-c">
            <div class="tbc-line">
              <div class="tl-title">任务名称： </div>
              <div class="tl-content">
                <el-input v-model="onetaskdata.name" placeholder="请输入任务名称"></el-input>
              </div>
            </div>
            <div class="tbc-line">
              <div class="tl-title">实际开始时间： </div>
              <div class="tl-content">
                <el-date-picker
                  v-model="onetaskdata.ActualStartTime"
                  type="date"
                  placeholder="请选择时间"
                  :disabled-date="disabledDates"
         
                >
                </el-date-picker>
              </div>
            </div>
            <div class="tbc-line">
              <div class="tl-title">实际结束时间： </div>
              <div class="tl-content">
                <el-date-picker
                  v-model="onetaskdata.ActualEndTime"
                  type="date"
                  placeholder="请选择时间"
                  :disabled-date="disabledDatee"
               
                >
                </el-date-picker>
              </div>
            </div>
            <div class="tbc-line">
              <div class="tl-title">是否为里程碑： </div>
              <div class="tl-content">
                <el-select v-model="onetaskdata.isMilestone" class="m-2" placeholder="Select" size="large">
                  <el-option
                    v-for="item in optionslcb"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="tbc-b">
            <div class="tbc-btn" @click="cancel">取消</div>
            <div class="tbc-btn" :class = "{disable:onetaskdata.isMilestone == -1}" @click="editTask">确认</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { getStorage } from "@/js/common";
import { GetTreeData,GetPlanProjectDropDownList,Update_Task,AddProductionProject } from "@/js/progressApi";
export default {
  props: {
    addfalse: Boolean,
    addtitle: String,
    editdata: Object,
  },
  setup(props, { emit }) {
    const cdata = getStorage("bscdata");
    const state = reactive({
      optionslcb:[ // 是否为里程碑
        {
          value: -1,
          label: '无',
        },
        {
          value: 0,
          label: '否',
        },
        {
          value: 1,
          label: '是',
        }
      ],
      options:[], // 计划任务下拉列表
      onetaskdata:{// 一个任务
        name:'', // 任务名称
        planStartTime:'', // 计划开始时间
        planEndTime:'', // 计划结束时间
        ActualEndTime:'', // 实际结束时间
        ActualStartTime:'', // 实际开始时间
        isMilestone:-1, // 是否 里程碑
        id:'',
        temporaryid:'',
        number:'',
        paretn_ProjectTask: {
            id: "00000000-0000-0000-0000-000000000000"
        },
        planProject: {
            id: ""
        }
      }, 
      taskboxshow:false, // 编辑一个任务的弹框
      ptaskid:"", // 数据id
      viewType:1,
      showweek:1,
      resposedata:'', // 后台返回的任务数据
      addtitle: "",
      selectDisable:false
    });
    const closefn = () => {
      emit("addfalsefn");
    };
    const recursion = (data) => {
      console.log(data);
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].childlist.length > 0) {
          let childlist = recursion(data[i].childlist);
          for (let j = 0; j < childlist.length; j++) {
            if (childlist[j].parent != 0) {
              arr.push(childlist[j]);
            } else {
              arr.push({
                id: childlist[j].id,
                number: childlist[j].number,
                name: childlist[j].name,
                start_date: childlist[j].start_date,
                end_date: childlist[j].end_date,
                ActualEndTime:childlist[j].ActualEndTime,
                ActualStartTime:childlist[j].ActualStartTime,
                day: childlist[j].day,
                isMilestone: childlist[j].isMilestone,
                parent: data[i].ID,
              });
            }
          }
          console.log(data[i],data[i].IsMilestone,data[i].IsMilestone == true ? "是" : "否")
          arr.push({
            id: data[i].ID,
            number: data[i].Number,
            name: data[i].Name,
            start_date: data[i].PlanStartTime,
            end_date: data[i].PlanEndTime,
            ActualEndTime:data[i].ActualEndTime,
            ActualStartTime:data[i].ActualStartTime,
            day: data[i].day,
            isMilestone: data[i].IsMilestone == true ? "是" : "否",
            parent: 0,
          });
        } else {
          arr.push({
            id: data[i].ID,
            number: data[i].Number,
            name: data[i].Name,
            start_date: data[i].PlanStartTime,
            end_date: data[i].PlanEndTime,
            ActualEndTime:data[i].ActualEndTime,
            ActualStartTime:data[i].ActualStartTime,
            day: data[i].day,
            isMilestone: data[i].IsMilestone == true ? "是" : "否",
            parent: 0,
          });
        }
      }
      return arr;
    };
    const resetonetaskdata = ()=>{
      state.onetaskdata = {// 一个任务
        name:'', // 任务名称
        planStartTime:'', // 计划开始时间
        planEndTime:'', // 计划结束时间
        ActualEndTime:'', // 实际结束时间
        ActualStartTime:'', // 实际开始时间
        isMilestone:-1, // 是否 里程碑
        id:'',
        temporaryid:'',
        number:'',
        paretn_ProjectTask: {
            // id: "00000000-0000-0000-0000-000000000000"
             id:state.onetaskdata&&state.onetaskdata.paretn_ProjectTask&&state.onetaskdata.paretn_ProjectTask.id?state.onetaskdata.paretn_ProjectTask.id:"00000000-0000-0000-0000-000000000000"
        },
        planProject: {
            id: state.ptaskid
        }
      }
    }
    const setganttevent = ()=>{
        gantt.showLightbox = function (id) {
            var task = gantt.getTask(id);
            state.onetaskdata.planProject.id =state.ptaskid;
            state.onetaskdata.temporaryid = task.id;
            state.onetaskdata = {
                id: task.id,
                temporaryid:task.id,
                name: task.name,
                number:task.number,
                ActualEndTime:task.ActualEndTime, // 实际结束时间
                ActualStartTime:task.ActualStartTime, // 实际开始时间
                planStartTime: task.start_date,
                planEndTime: task.end_date,
                isMilestone: task.isMilestone == "否"?0:task.isMilestone == "是"?1:-1,
                paretn_ProjectTask: {
                    id: task.parent == 0 ? "00000000-0000-0000-0000-000000000000" : task.parent
                },
                planProject: {
                    id: state.ptaskid
                }
            }
            console.log(state.onetaskdata,259)
            state.taskboxshow = true;
        }      
    }
    const initgantt = () => {
      //start 自定义第一时间轴 （为合并行效果）
      gantt.config.scale_unit = "year"; //按年显示
      gantt.config.open_tree_initially = true;
      gantt.config.step = 1; //步进，默认为1
      gantt.config.date_scale = "%Y年"; //日期格式 先数字后文字
      //剩下的时间轴
      gantt.config.subscales = [
        { unit: "month", step: 1, date: "%M" },
        { unit: "day", step: 1, date: "%d-周%D" },
      ];
      gantt.config.scale_height = 60; //设置时间尺度和Grid树的高度
      //end

      //Start高亮显示周末
      gantt.templates.scale_cell_class = function (date) {
        if (date.getDay() == 0 || date.getDay() == 6) {
          return "weekend";
        }
      };
      gantt.templates.timeline_cell_class = function (task, date) {
        if (date.getDay() == 0 || date.getDay() == 6) {
          return "weekend";
        } 
      };
      //End

      //Start 显示/隐藏周末
      if (state.showweek == 1) {
        // 0 refers to Sunday, 6 - to Saturday
        gantt.ignore_time = function (date) {
          if (date.getDay() == 0 || date.getDay() == 6) return false;
        };
      } else {
        // 0 refers to Sunday, 6 - to Saturday
        gantt.ignore_time = function (date) {
          if (date.getDay() == 0 || date.getDay() == 6) return true;
        };
      }
      //End

      //Start 设置时间轴显示视图
      if (state.viewType == 1) {
        gantt.config.scale_unit = "day"; //按天显示
        gantt.config.step = 1; //步进，默认为1
        gantt.config.date_scale = "%d-周%D"; //日期格式 先数字后文字
        gantt.config.subscales = []; //清空其他行时间轴
      } else if (state.viewType == 2) {
        gantt.config.scale_unit = "month"; //按月显示
        gantt.config.step = 1; //步进，默认为1
        gantt.config.date_scale = "%M"; //日期格式 先数字后文字
        gantt.config.subscales = []; //清空其他行时间轴
      } else if (state.viewType == 3) {
        gantt.config.scale_unit = "year"; //按年显示
        gantt.config.step = 1; //步进，默认为1
        gantt.config.date_scale = "%Y年"; //日期格式 先数字后文字
        gantt.config.subscales = []; //清空其他行时间轴
      } else if (state.viewType == 4) {
        //start 自定义第一时间轴 （为合并行效果）
        gantt.config.scale_unit = "year"; //按年显示
        gantt.config.step = 1; //步进，默认为1
        gantt.config.date_scale = "%Y年"; //日期格式 先数字后文字
        //剩下的时间轴
        gantt.config.subscales = [
          { unit: "month", step: 1, date: "%M" },
          { unit: "day", step: 1, date: "%d-周%D" },
        ];
        //end
      }
      //End

      //Start 设置时间轴的高
      gantt.config.task_height = 20;
      gantt.config.row_height = 40;
      //End

      gantt.config.keep_grid_width = true;

      gantt.config.autofit = true;
      gantt.config.autoscroll = true; //把任务或者连线拖拽到浏览器屏幕外时，自动触发滚动效果，相当于拖动滚动条
      gantt.config.layout = {
        css: "gantt_container",
        cols: [
          {
            width: 600,
            min_width: 400,
            rows: [
              {
                view: "grid",
                scrollX: "gridScroll",
                scrollable: true,
                scrollY: "scrollVer",
              },

              // horizontal scrollbar for the grid
              { view: "scrollbar", id: "gridScroll", group: "horizontal" },
            ],
          },
          { resizer: true, width: 1 },
          {
            rows: [
              { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },
              // horizontal scrollbar for the timeline
              { view: "scrollbar", id: "scrollHor", group: "horizontal" },
            ],
          },
          { view: "scrollbar", id: "scrollVer" },
        ],
      };
      gantt.config.columns = [
        {
          name: "wbs",
          label: "大纲",
          align: "center",
          resize: true,
          tree: true,
          width: 150,
          template: gantt.getWBSCode,
        },
        {
          name: "number",
          label: "任务编号",
          align: "center",
          resize: true,
          width: 110,
        },
        {
          name: "name",
          label: "任务名称",
          align: "center",
          resize: true,
          width: 200,
        },
        {
          name: "start_date",
          label: "计划开始时间",
          align: "center",
          resize: true,
          width: 180,
        },
        {
          name: "end_date",
          label: "计划结束时间",
          align: "center",
          resize: true,
          width: 180,
        },
        {
          name: "ActualStartTime",
          label: "实际开始时间",
          align: "center",
          resize: true,
          width: 180,
        },
        {
          name: "ActualEndTime",
          label: "实际结束时间",
          align: "center",
          resize: true,
          width: 180,
        },
        {
          name: "day",
          label: "工期",
          align: "center",
          resize: true,
          width: 100,
        },
        {
          name: "isMilestone",
          label: "是否为里程碑",
          align: "center",
          resize: true,
          width: 180,
        }
      ];
      gantt.config.date_format = "%Y-%n-%d %H:%i:%s";
      gantt.init("gantt_here");
      gantt.clearAll();
      // var dd = app.recursion(app.model);
      if(state.resposedata){ // 如果是解析后台返回的数据
        gantt.parse({
          data: state.resposedata,
        });
      }
      //常用功能性的设置
      //提示文本时间格式控制
      gantt.templates.tooltip_date_format = function (date) {
        var formatFunc = gantt.date.date_to_str("%Y-%m-%d %H:%i:%s");
        return formatFunc(date);
      };
            gantt.open(state.onetaskdata&&state.onetaskdata.paretn_ProjectTask&&state.onetaskdata.paretn_ProjectTask.id?state.onetaskdata.paretn_ProjectTask.id:'')  
      //指定工具提示的文本
      //gantt.templates.tooltip_text = function (start, end, task) {
      //    return "<b>任务编号:</b> " + task.number + "<br/>" +
      //        "<b>任务名称:</b > " + task.name + "<br/>" +
      //        "<b>开始时间:</b>" + gantt.templates.tooltip_date_format(task.start_date) + "<br/>" +
      //        "<b>结束时间:</b>" + gantt.templates.tooltip_date_format(task.end_date) + "<br/>" +
      //        "<b>工期:</b>" + task.type + "<br/>";
      //};

      //在时间轴显示任务名称
      gantt.templates.task_text = function (start, end, task) {
        return task.number;
      };
      //在任务栏的完成部分中指定文本
      //gantt.templates.progress_text = function (start, end, task) {
      //    return "";
      //};
    };
    const getGtreedata = ()=>{
      GetTreeData({planprojectID:state.ptaskid}).then((res02) => {
        // 获取树结构
        let { Code, Data, Message } = res02.data;
        if (Code == 1 && Data) {
          state.resposedata = recursion(Data);
          initgantt();
        } else {
          ElMessage({
            showClose: true,
            message: Message,
            type: "error",
          });
        }
      });
    }
    const getSelectList = ()=>{
      GetPlanProjectDropDownList({
        ProjectID: cdata.pid
      }).then((res) => {
        let { Code,Data,Message } = res.data;
        if (Code == 1 && Data && Data.length) {
          state.options = Data;
        } else {
          ElMessage({
            showClose: true,
            message: Message,
            type: "error",
          });
        }
      });
    }
    const methods = {
   startfn:()=>{
        state.onetaskdata.ActualStartTime=dayjs(state.onetaskdata.ActualStartTime).format('YYYY-MM-DD')
      },
       endfn:()=>{
        state.onetaskdata.ActualEndTime=dayjs(state.onetaskdata.ActualEndTime).format('YYYY-MM-DD')
      },
    

      AddProductionProject:()=>{// 提交保存数据
        if(state.ptaskid){
          const datas = {
            "planProject": state.ptaskid,
            "user": cdata.uid,
            "projectID": cdata.pid
          }
          AddProductionProject(datas).then((res) => {
            console.log(res);
            let { Code,Message } = res.data;
            if (Code == 1) {
              ElMessage({
                showClose: true,
                message: Message,
                type: "success",
              });
              emit("GetPlanProjectList");
              closefn();
            } else {
              ElMessage({
                showClose: true,
                message: Message,
                type: "error",
              });
            }
          });
        }
      },
      selectChange:()=>{
        console.log(state.ptaskid)
        getGtreedata();
      },
      getNewDayAdd:(dateTemp)=> {
      dateTemp=+dateTemp +1000*60*60*24;
      dateTemp=new Date(dateTemp);
      return dateTemp.getFullYear()+"-"+(dateTemp.getMonth()+1)+"-"+dateTemp.getDate();
      },
      editTask:()=>{
        let onetaskdata = state.onetaskdata;
        console.log(onetaskdata,11,onetaskdata.planStartTime,22,onetaskdata.planEndTime);
        if(onetaskdata.ActualStartTime=='Invalid Date'){
          state.onetaskdata.ActualStartTime=''
          onetaskdata.ActualStartTime=''
        }
         if(onetaskdata.ActualEndTime=='Invalid Date'){
          state.onetaskdata.ActualEndTime=''
          onetaskdata.ActualEndTime=''
        }
        // onetaskdata.ActualStartTime=='Invalid Date'?'':onetaskdata.ActualStartTime
        // onetaskdata.ActualEndTime=='Invalid Date'?'':onetaskdata.ActualEndTime
        // if(onetaskdata.ActualStartTime&&onetaskdata.ActualStartTime !='Invalid Date'&&onetaskdata.ActualEndTime&& onetaskdata.ActualEndTime!='Invalid Date' && onetaskdata.isMilestone != -1){
          if(onetaskdata.isMilestone != -1){
          methods.startfn()
          methods.endfn()
          const datas = {
            "number": onetaskdata.number,
            "name": onetaskdata.name,
            "planStartTime": onetaskdata.planStartTime,
            "planEndTime": methods.getNewDayAdd(onetaskdata.planEndTime),
            "actualStartTime": onetaskdata.ActualStartTime=='Invalid Date'?'':onetaskdata.ActualStartTime,
            "actualEndTime": onetaskdata.ActualEndTime=='Invalid Date'?'':onetaskdata.ActualEndTime,
            "planProject": onetaskdata.planProject.id,
            "isMilestone": onetaskdata.isMilestone == 0?false:true,
            "user": cdata.uid,
            "paretn_ProjectTask": onetaskdata.paretn_ProjectTask.id
          }
          if(onetaskdata.id){
            datas.id = onetaskdata.id;
          }
          console.log(datas);
          Update_Task(datas).then((res) => {
            let { Code,Message } = res.data;
            if (Code == 1) {
              getGtreedata();
              resetonetaskdata();
              state.taskboxshow = false;
              state.delshow = false;
              ElMessage({
                showClose: true,
                message: Message,
                type: "success",
              });
            } else {
              ElMessage({
                showClose: true,
                message: Message,
                type: "error",
              });
            }
          });
        }

      },
      cancel:()=> { // 修改任务弹框取消
        console.log(state.onetaskdata)
          let task = gantt.getTask(state.onetaskdata.temporaryid);
          if (task.$new){
            gantt.deleteTask(task.temporaryid);
          }
          resetonetaskdata();
          state.taskboxshow = false;
          state.delshow = false;
      },
      down:()=> {
          if(state.resposedata){
            gantt.exportToExcel({
                server: "https://export.dhtmlx.com/gantt/project",
                name: "计划任务.xlsx",
                data: state.resposedata
            })

          }
      },
      showWeek:(type) => {
        state.viewType = type;
        initgantt();
      },
      typeChange:(type) => {
        state.viewType = type;
        initgantt();
      },
      disabledDates: (time) => {// 任务计划开始时间 校验 ActualEndTime,ActualStartTime
        return state.onetaskdata.ActualEndTime && time.getTime() > new Date(state.onetaskdata.ActualEndTime);
      },
      disabledDatee: (time) => {// 任务计划结束时间 校验
        return state.onetaskdata.ActualStartTime && time.getTime() < new Date(state.onetaskdata.ActualStartTime);
      }
    };
    onMounted(() => {
      state.addtitle = props.addtitle;
      console.log(props.editdata)
      if (state.addtitle == "编辑实际进度") {
        state.selectDisable = true;
        state.ptaskid = props.editdata.PlanProjectID;
        getGtreedata();
      }
      getSelectList();
      setganttevent();
    });
    return {
      ...methods,
      closefn,
      ...toRefs(state),
    };
  },
};
</script>
<style lang="scss" scoped>
.addPlan {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  .addPlan-wrap {
    width: 80%;
    min-height: 70%;
    background: #ffffff;
    border-radius: 4px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    .addPlan-top {
      padding: 0 20px;
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #e3edf8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #666666;
      img {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }
  }

  .addPlan-content {
    width: 100%;
    padding: 0 20px 20px;
    box-sizing: border-box;
    position: absolute;
    top: 50px;
    bottom: 0;
    .ff-c {
      margin: 20px 0 0;
      display: flex;
      align-items: center;
      .fi-c {
        display: flex;
        align-items: center;
        margin: 0 50px 0 0;
        .it {
          font-size: 14px;
          color: #555555;
          word-break: keep-all;
          margin: 0 10px 0 0;
        }
      }
    }
    .gt-c {
      position: absolute;
      top: 75px;
      bottom: 56px;
      left: 20px;
      right: 20px;
      .gtc-t {
        display: flex;
        .gq-btn {
          height: 30px;
          line-height: 30px;
          padding: 0 10px;
          border: 1px solid #ddd;
          border-radius: 3px;
          margin: 0 5px 0 0;
          cursor: pointer;
          font-size: 14px;
          color: #666;
        }
      }
      .gtc-b {
        position: absolute;
        top: 35px;
        left: 0;
        right: 0;
        bottom: 0;
        border:1px solid #555;
      }
    }
    .btns{
      position:absolute;
      bottom:0;
      height:50px;
      left:0;
      right:0;
      padding:0 30px;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      align-items:center;
      .ac-btn{
        height:36px;
        line-height: 36px;
        padding:0 20px;
        margin:0 0 0 10px;
        border-radius: 4px;
        border: 1px solid #C6C6C6;
        font-size: 14px; 
        color: #ADBAAE;
        cursor:pointer;
        &:nth-child(2){
          background: #027AFF;
          border-radius: 4px;
          border:1px solid transparent;
          color: #FFFFFF;
        }
        &.disable{
          opacity: 0.5;
          cursor:no-drop;
        }
      }
    }
  }
  .task-box{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 9;
    .tbox-c{
      width: 500px;
      height: 320px;
      background: #fff;
      border-radius: 3px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .tbc-t{
        height:40px;
        display: flex;
        justify-content: flex-end;
        border-bottom:1px solid #ddd;
        .close-btn{
          padding:0 20px;
          font-size: 18px;
          line-height: 40px;
          cursor: pointer;

        }
      }
      .tbc-c{
        box-sizing: border-box;
        padding:0 20px;
        .tbc-line{
          display: flex;
          align-items: center;
          margin:10px 0 0;
          .tl-title{
            width:120px;
            text-align: right;
            font-size: 14px;
            color:#333;
          }
          .tl-content{
            width:220px;
            .el-select{
              width:100%;
            }
          }
        }
      }
      .tbc-b{
        position:absolute;
        bottom:0;
        width:100%;
        height:50px;
        border-top:1px solid #ddd;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .tbc-btn{
          padding:0 10px;
          border-radius: 3px;
          cursor:pointer;
          height: 30px;
          line-height: 30px;
          font-size: 14px;
          color:#fff;
          &:nth-child(1){
            background: #ff9900;
          }
          &:nth-child(2){
            background: red;
          }
          &:nth-child(3){
            background: green;
          }
        }
      }
    }
  }
}
</style>